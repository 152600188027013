import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/SEO"
import Head from "../components/Head"

const PageLayout = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  /* border: 2px solid black; */
`

const StyledVideoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const StyledVideoSource = styled.iframe`
  width: 350px;
  /* height: 200px; */
  margin: 1rem 0;
`

const VideoPage = () => {
  return (
    <Layout>
      <SEO title="videos" />
      <Head title="videos" />
      <PageLayout>
        <h1>FactoryCat Videos</h1>
        <StyledVideoContainer>
          <StyledVideoSource
            src="https://www.youtube.com/embed/0Bwftb0fC4s"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/AEXnDHtllQ8"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/myutUQniqkk"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/nr0SX8xviSk"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/LQggWmRBTOs"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />

          <StyledVideoSource
            src="https://www.youtube.com/embed/BZ_v5LcXJwo"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/NaGoDJxcSGM"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/GkBsYDUa5us"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/3PPBI6XiIn4"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/t96DKSW6l6I"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/484Gmb88NRo"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/WR6UOeDwRNU"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/NDKUIM3FAhQ"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/m1cPMkVicFc"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/oxuWaTUefwA"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/2pxKs8KQUPA"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/74sf_EdChWA"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/9SAdO5PMuuU"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/i7niewiZWkY"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/4nLmcwDVSVk"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/2CuA-6-nEXQ"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/938FZwY5AG4"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/6wtOBFzkmjw"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/5VXPOb6m_cY"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/r_FVO6_F8q4"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
          <StyledVideoSource
            src="https://www.youtube.com/embed/RFP2Nk5bUxY"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameborder="0"
          />
        </StyledVideoContainer>
      </PageLayout>
    </Layout>
  )
}

export default VideoPage
